<template>
  <div class="login-container">
    <div class="login-header"></div>
    <div class="card">
      <h1 class="title">{{$t('Create your account')}}</h1>
      <h4 class="subtitle">{{$t('Please note that phone verification is required for signup.')}}</h4>
      <h4 class="subtitle">{{$t('Your number will only be used to verify your identity')}} </h4>
      <h4 class="subtitle">{{$t('for security purposes.')}}</h4>
      <!-- <div  -->
        <el-form 
          class="form-box" 
          :rules="rules"
          :model="form">
            <el-form-item prop="username">
                <el-input :placeholder="($t('Account'))" class="input-box" v-model="form.username" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input 
                  :placeholder="($t('Password'))" 
                  class="input-box" 
                  v-model="form.password" 
                  type="password" 
                  auto-complete="off"
                  show-password></el-input>
            </el-form-item>
            <el-form-item>
                <el-input 
                  :placeholder="($t('Confirm Password'))" 
                  class="input-box" 
                  v-model="confirmPassword" 
                  type="password" 
                  auto-complete="off"
                  show-password></el-input>
            </el-form-item>
            <el-form-item>
                <el-input :placeholder="($t('Email'))" class="input-box" v-model="mailAddr" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <div class="verify-box">
                <el-input :placeholder="($t('VerifyCode'))" class="verify-input" v-model="verifyCode" auto-complete="off"></el-input>
                <el-button class="send-button" @click="sendVerifyCode" :disabled="buttonDisabled">{{countDownText}}</el-button>
              </div>
            </el-form-item>
            <el-form-item>
                <el-input :placeholder="($t('Phone'))" class="input-box" v-model="phoneNum" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item>
                <div class="captcha-box">
                  <el-input :placeholder="($t('captcha'))" class="captcha-input" v-model="inputCaptcha" auto-complete="off"></el-input>
                  <img class="captcha-img" :src="captchaSrc" @click="queryCaptcha"/>
                </div>
            </el-form-item>
            <el-form-item>
                <div class="login-button" type="primary" @click="registerFunc">{{$t('Create')}}</div>
            </el-form-item>
        </el-form>
      </div>
      <div class="login-footer">
        <span class="policy-text">{{$t('Terms of use')}}</span>
        <span class="split-line"></span>
        <span class="policy-text">{{$t('Privacy policy')}}</span>
      </div>
  </div>
</template>

<script>
import loginApi from '../../api/loginApi';

export default {
  data(){
    return {
      // username: '',
      // password: '',
      confirmPassword: '',
      mailAddr: '',
      phoneNum: '',
      verifyCode: '',
      countDownText: '发送验证码',
      countDownTimer: null,
      secondText: 60,
      buttonDisabled: false,
      inputCaptcha: '',
      captchaSrc: '',
      captchaText: 'null',
      form: {
        username: '',
        password: '',
        // confirmPassword: '',
        // mailAddr: '',
        // phoneNum: '',
        // verifyCode: '',
        // inputCaptcha: ''
      },
      rules: {
        username: [{validator: this.validateUsername,trigger: 'blur'}],
        password: [{ required: true, message: '密码长度至少6位且包含英文大小写', trigger: 'blur' },
          { min: 6, message: '密码长度至少6位且包含英文大小写', trigger: 'blur' },
        ],
      }
    }
  },
  beforeMount(){
    this.queryCaptcha()
  },
  methods: {
    validateUsername(rule,value,callback) {
      // 定义允许的字符范围，包括字母、数字和部分特殊字符
      var allowedCharacters = /^[a-zA-Z0-9_\-@.]+$/;

      if(value == ''){
        callback(new Error('Is required'))
      }else if(!allowedCharacters.test(value)){
        callback(new Error("不允许特殊字符"))
      }else{
        callback()
      }
    },

    async registerFunc(){
      if(this.inputCaptcha.toLocaleLowerCase()==this.captchaText.toLocaleLowerCase()
      &&this.inputCaptcha!=''){
        if(this.form.password==this.confirmPassword){
          const client_id = localStorage.getItem('client_id') != null ? localStorage.getItem('client_id') : 'sdf'
          const param = {
            username: this.form.username,
            mailAddr: this.mailAddr,
            phoneNo: this.phoneNum,
            passWord: this.form.password,
            captcha: this.verifyCode,
            client_id: client_id
          }
          const res = await loginApi.registerFunc(param)
          console.log('registerFunc res = ',res);
          alert(res.msg)
          if(res.code == 0){
            this.$router.push('/Login')
          }
        }else{
          alert('两次密码不一致')
        }
      }else{
        alert('请输入验证码')
      }
    },
    async sendVerifyCode(){
      const mailRegExp = new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
      if(mailRegExp.test(this.mailAddr)==false){
          alert('请输入正确的邮箱!')
          return
      }
      this.buttonDisabled = true
      this.countDownTimer = setInterval(()=>{
          this.secondText = this.secondText - 1
          this.countDownText = `${this.secondText}S后重发`
          if(this.secondText<=0){
              this.buttonDisabled = false
              clearInterval(this.countDownTimer)
              this.countDownTimer = null
              this.secondText = 60
              this.countDownText = '发送验证码'
          }
      },1000)
      
      const param = {
        mailAddr: this.mailAddr
      }
      let res = await loginApi.queryVerifyMail(param)
      alert(res.msg+this.mailAddr)
    },
    async queryCaptcha(){
      const res = await loginApi.queryCaptcha()
      this.captchaSrc = res.data.img
      this.captchaText = res.data.captchaText
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  /* background-color: #f5f5f5; */
}

.login-header{
  width: 100px;
  height: 100px;
  background-color: #19c37d;
  /* color: white; */
  text-align: center;
  line-height: 50px;
  font-weight: 900;
  margin-top: 32px;
  background: url('../assets/logo.png');
  background-size: 100% 100%;
  flex-shrink: 0;
}

.card {
  /* width: 400px; */
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 30px;
  text-align: center;
  /* margin-bottom: 20px; */
}

.subtitle{
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.form-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.input-box{
  width: 320px;
  height: 52px;
  /* margin-top: 16px; */
}

/* .el-input__inner{
  width: 320px;
  height: 52px;
  padding-left: 10px;
  border-width: 1px;
  border-color: gray;
  border-radius: 2px;
  box-sizing: border-box;
} */

.forget-pass{
  color: #19c37d;
  margin-top: 16px;
  text-align: left;
}

.login-button{
  width: 322px;
  height: 52px;
  background-color: #19c37d;
  margin-top: 16px;
  line-height: 52px;
  font-weight: 600;
  font-size: 20px;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}

.signup-box{
  margin-top: 16px;
}

.text1{
  font-weight: 400;
  font-size: 20px;
}

.signup-btn{
  font-weight: 600;
  color: #19c37d;
  cursor: pointer;
}

.login-footer{
    height: 50px;
    margin-bottom: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
  }

  .policy-text{
    color: #19c37d;
    cursor: pointer;
  }

  .split-line{
    height: 20px;
    width: 1px;
    display: inline-block;
    background: black;
    margin: 0 10px;
  }

.captcha-box{
  display: flex;
}

.captcha-input{
  width: 200px;
  /* margin-top: 16px; */
}

.captcha-img{
  width: 120px;
  height: 50px;
  /* margin-top: 16px; */
  display: inline;
  object-fit: cover;
  padding: 1px;
  cursor: pointer;
}

.verify-box{
  display: flex;
  /* margin-top: 16px; */
}

.verify-input{
  width: 200px;
}

.send-button{
  width: 120px;
  height: 50px;
  background: #19c37d;
  border: #19c37d;
  margin-top: 1px;
  color: white;
  cursor: pointer;
}
</style>
